// This is made for the hero component

'use strict';

$('.js-hero-image').on('load', function (e) {

    const $hero = $(this).closest('.js-hero');
    const imgSrc = this.currentSrc || this.src;

    $hero.css('background-image', 'url(' + imgSrc + ')');
    $hero.addClass('is-loaded');

}).trigger('load');
